<template>
    <div>
      <b-card>
        <h4 class="mb-3">Payroll Calculation</h4>
  
        <!-- Filters (Month, Year, Department) -->
        <b-form inline class="mb-2">
          <b-form-group label="Month:" label-size="sm">
            <!-- <b-form-select size="sm" v-model="month">
              <b-form-select-option
                v-for="m in months"
                :key="m.value"
                :value="m.value"
              >
                {{ m.text }}
              </b-form-select-option>
            </b-form-select> -->
            <v-select
                v-model="month"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="months"
                :reduce="(option) => option.text"
                
            />
          </b-form-group>
  
          <b-form-group label="Year:" label-size="sm" class="ml-3">
            <b-form-input
              size="sm"
              type="number"
              v-model="year"
              style="width: 80px;"
            />
          </b-form-group>
  
          <b-form-group label="Department:" label-size="sm" class="ml-3">
            <!-- <b-form-select size="sm" v-model="departmentFilter">
              <b-form-select-option value="">All</b-form-select-option>
              <b-form-select-option
                v-for="dep in departmentList"
                :key="dep"
                :value="dep"
              >
                {{ dep }}
              </b-form-select-option>
            </b-form-select> -->
            <v-select
                v-model="departmentFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departmentList"
                :reduce="(option) => option.title"
                multiple
                :close-on-select="false"
            />
          </b-form-group>
  
          <b-button size="sm" variant="primary" class="ml-2" @click="loadPayrolls">
            Load
          </b-button>
          <b-button size="sm" variant="success" class="ml-2" @click="calculatePayroll">
            Re-Calculate
          </b-button>
        </b-form>
  
        <!-- Summary -->
        <b-card class="mb-3">
          <b-row>
            <b-col md="4">
              <p><strong>Total Employees:</strong> {{ summary.totalEmployees }}</p>
              <p><strong>Total Salary:</strong> {{ summary.totalSalary }}</p>
            </b-col>
            <b-col md="4">
              <p><strong>Total Pay:</strong> {{ summary.totalPay }}</p>
              <p><strong>Total Hold:</strong> {{ summary.totalHold }}</p>
              <p><strong>Total Paid:</strong> {{ summary.totalPaid }}</p>
            </b-col>
            <b-col md="4">
              <p>
                <strong>PF Employee/Employer:</strong>
                {{ summary.pfCompliance && summary.pfCompliance.totalEmployeePF }} /
                {{ summary.pfCompliance && summary.pfCompliance.totalEmployerPF }}
              </p>
              <p>
                <strong>ESIC Employee/Employer:</strong>
                {{ summary.esicCompliance && summary.esicCompliance.totalEmployeeESIC }} /
                {{ summary.esicCompliance && summary.esicCompliance.totalEmployerESIC }}
              </p>
            </b-col>
          </b-row>
        </b-card>
  
        <!-- Payroll Table -->
        <b-table
          :items="payrolls"
          :fields="fields"
          key-field="id"
          responsive
          striped
          hover
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(salaryStatus)="data">
            <b-form-select v-model="data.item.salaryStatus" @change="updateStatus(data.item)">
              <b-form-select-option value="Pay">Pay</b-form-select-option>
              <b-form-select-option value="Hold">Hold</b-form-select-option>
              <b-form-select-option value="Paid">Paid</b-form-select-option>
            </b-form-select>
          </template>
        </b-table>
  
        <!-- Bulk actions -->
        <div class="mt-2">
          <b-button
            size="sm"
            variant="warning"
            class="mr-2"
            :disabled="!selectedPayrollIds.length"
            @click="bulkUpdate('Hold')"
          >
            Hold Selected
          </b-button>
          <b-button
            size="sm"
            variant="info"
            class="mr-2"
            :disabled="!selectedPayrollIds.length"
            @click="bulkUpdate('Pay')"
          >
            Pay Selected
          </b-button>
          <b-button
            size="sm"
            variant="success"
            :disabled="!selectedPayrollIds.length"
            @click="bulkUpdate('Paid')"
          >
            Paid Selected
          </b-button>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BTable, BAvatar, BBadge, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, BModal, VBModal, BDropdown, BDropdownItem, BFormRadioGroup, VBPopover, BFormFile
  } from 'bootstrap-vue'
  import payrollService from '@/services/payrollService.js';
  import vSelect from 'vue-select'

  export default {
    name: 'PayrollCalculationView',
    data() {
      return {
        departmentFilter: '',
        departmentList: [{"title": "All"},{"title": "Sattva"},{"title": "HPE"},{"title": "KPMG"},{"title": "Nikitha"},{"title": "LnT"},{"title": "Reliance"},{"title": "William Sonoma"},{"title": "Hinduja"},{"title": "Adani Assam"},{"title": "Adani Bihar"},{"title": "Adani Mumbai"}], // or fetch dynamically
        month: null,
        year: new Date().getFullYear(),
        payrolls: [],
        summary: {},
        selectedPayrollIds: [],
        fields: [
          { key: 'Name', label: 'Name' },
          { key: 'department', label: 'Department' },
          { key: 'gross', label: 'Gross' },
          { key: 'net_pay', label: 'Net Pay' },
          { key: 'salaryStatus', label: 'Status' },
        ],
        months: [
          { text: 'January', value: 1 },
          { text: 'February', value: 2 },
          { text: 'March', value: 3 },
          { text: 'April', value: 4 },
          { text: 'May', value: 5 },
          { text: 'June', value: 6 },
          { text: 'July', value: 7 },
          { text: 'August', value: 8 },
          { text: 'September', value: 9 },
          { text: 'October', value: 10 },
          { text: 'November', value: 11 },
          { text: 'December', value: 12 },
        ],
      };
    },
    components: {
        BTable, BAvatar, BBadge, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, BModal, VBModal, BDropdown, BDropdownItem, BFormRadioGroup, VBPopover, BFormFile, vSelect
    },
    methods: {
      loadPayrolls() {
        const params = {
          month: this.month,
          year: this.year,
        };
        if (this.departmentFilter) {
          params.department = this.departmentFilter;
        }
  
        payrollService.getPayrolls(params)
          .then(res => {
            this.payrolls = res.data;
            this.loadSummary();
          })
          .catch(err => console.error(err));
      },
      loadSummary() {
        const params = {
          month: this.month,
          year: this.year,
        };
        if (this.departmentFilter) {
          params.department = this.departmentFilter;
        }
  
        payrollService.getSummary(params)
          .then(res => {
            this.summary = res.data;
          })
          .catch(err => console.error(err));
      },
      calculatePayroll() {
        payrollService.calculatePayroll({
          month: this.month,
          year: this.year,
        })
        .then(() => {
          this.$bvToast.toast('Payroll calculation done', {
            title: 'Success',
            variant: 'success',
          });
          this.loadPayrolls();
        })
        .catch(err => {
          this.$bvToast.toast('Error in calculation', { variant: 'danger' });
          console.error(err);
        });
      },
      updateStatus(record) {
        payrollService.updateSalaryStatus(record.id, record.salaryStatus)
          .then(() => {
            this.$bvToast.toast('Status updated', { variant: 'success' });
            this.loadSummary();
          })
          .catch(err => {
            this.$bvToast.toast('Error updating status', { variant: 'danger' });
            console.error(err);
          });
      },
      onRowSelected(selectedRecords) {
        this.selectedPayrollIds = selectedRecords.map(r => r.id);
      },
      bulkUpdate(status) {
        payrollService.bulkUpdateSalaryStatus(this.selectedPayrollIds, status)
          .then(() => {
            this.$bvToast.toast(`Bulk status updated to ${status}`, {
              variant: 'success',
            });
            this.loadPayrolls();
          })
          .catch(err => {
            this.$bvToast.toast('Bulk update error', { variant: 'danger' });
            console.error(err);
          });
      },
    },
    created() {
      this.loadPayrolls();
    },
  };
  </script>
  <style scoped lang="scss">

  @import "@core/scss/vue/libs/vue-select.scss";
//   @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>